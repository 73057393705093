:root {
  /* tailwind colors */
  --color-primary: #1a202c;
  --color-secondary: #2d3748;
  --color-tertiary: #4a5568;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-red: #e53e3e;
  --color-orange: #ed8936;
  --color-yellow: #ecc94b;
  --color-green: #48bb78;
  --color-teal: #38b2ac;
  --color-blue: #3182ce;
  --color-indigo: #5a67d8;
  --color-purple: #9f7aea;
  --color-pink: #ed64a6;

  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-32: 8rem;
  --spacing-40: 10rem;
  --spacing-48: 12rem;
  --spacing-56: 14rem;
  --spacing-64: 16rem;

  --border-width-0: 0;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;

  --border-radius-none: 0;
  --border-radius-sm: 0.125rem;
  --border-radius-default: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-full: 9999px;

  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --font-size-7xl: 5rem;

  --font-family-sans: 'Helvetica Neue', Arial, sans-serif;
  --font-family-serif: Georgia, 'Times New Roman', Times, serif;
  --font-family-mono: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  --line-height-none: 1;
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;

  --letter-spacing-tighter: -0.05em;
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;

  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;

  --opacity-0: 0;
  --opacity-25: 0.25;
  --opacity-50: 0.5;
  --opacity-75: 0.75;
  --opacity-100: 1;

  --z-index-auto: auto;
  --z-index-0: 0;
  --z-index-10: 10;
  --z-index-20: 20;
  --z-index-30: 30;
  --z-index-40: 40;
  --z-index-50: 50;

  --shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-default: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --shadow-none: none;

  --min-h-screen: 100vh;
  --min-w-screen: 100vw;
  --max-h-screen: 100vh;
  --max-w-screen: 100vw;

  --grid-cols-complex: repeat(12, minmax(0, 1fr));
  --grid-col-span-7: span 7 / span 7;
  --grid-col-span-8: span 8 / span 8;
  --grid-col-span-9: span 9 / span 9;
  --grid-col-span-10: span 10 / span 10;
  --grid-col-span-11: span 11 / span 11;
  --grid-col-span-12: span 12 / span 12;

  --flex-1: 1 1 0%;
  --flex-auto: 1 1 auto;
  --flex-initial: 0 1 auto;
  --flex-none: none;

  --width-1-2: 50%;
  --width-1-3: 33.33333%;
  --width-2-3: 66.66667%;
  --width-1-4: 25%;
  --width-2-4: 50%;
  --width-3-4: 75%;
  --width-1-5: 20%;
  --width-2-5: 40%;
  --width-3-5: 60%;
  --width-4-5: 80%;
  --width-1-6: 16.66667%;
  --width-2-6: 33.33333%;
  --width-3-6: 50%;
  --width-4-6: 66.66667%;
  --width-5-6: 83.33333%;
  --width-full: 100%;

  --height-1-2: 50%;
  --height-1-3: 33.33333%;
  --height-2-3: 66.66667%;
  --height-1-4: 25%;
  --height-2-4: 50%;
  --height-3-4: 75%;
  --height-1-5: 20%;
  --height-2-5: 40%;
  --height-3-5: 60%;
  --height-4-5: 80%;
  --height-1-6: 16.66667%;
  --height-2-6: 33.33333%;
  --height-3-6: 50%;
  --height-4-6: 66.66667%;
  --height-5-6: 83.33333%;
  --height-full: 100%;
}
