.afterPayCheckout {
  @apply bg-[#b2fce4];
}

.returnPolicy {
  @apply border border-spring-neutral-20;
}

.googlePayCheckout {
  @apply bg-black text-white;
}
