:root {
  --animationDuration: 1s;
}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
.puff-in-center {
  animation: puff-in-center var(--animationDuration) cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd var(--animationDuration) cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
.fade-out {
  animation: fade-out var(--animationDuration) ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
.fade-in {
  animation: fade-in var(--animationDuration) ease-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-out
 * ----------------------------------------
 */
.fade-in-out {
  animation: fade-in-out var(--animationDuration) ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
.tracking-in-expand {
  animation: tracking-in-expand var(--animationDuration) cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-top
 * ----------------------------------------
 */
.tracking-in-expand-fwd-top {
  animation: tracking-in-expand-fwd-top var(--animationDuration) cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    transform: translateZ(0) translateY(-35vh);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.tracking-in-contract {
  animation: tracking-in-contract var(--animationDuration) cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

/**
 * ----------------------------------------
 * animation kenburns-bottom
 * ----------------------------------------
 */
.kenburns-bottom {
  animation: kenburns-bottom var(--animationDuration) ease-out both;
}

@keyframes kenburns-bottom {
  0% {
    transform: scale(0.8) translateY(0);
    transform-origin: 50% 84%;
  }

  100% {
    transform: scale(1) translateY(15px);
    transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
.kenburns-top {
  animation: kenburns-top var(--animationDuration) ease-out both;
}

@keyframes kenburns-top {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(0);
    transform-origin: 50% 16%;
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(-15px);
    transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-left
 * ----------------------------------------
 */
.kenburns-left {
  animation: kenburns-left var(--animationDuration) ease-out both;
}

@keyframes kenburns-left {
  0% {
    transform: scale(1) translate(0, 0);
    transform-origin: 16% 50%;
  }

  100% {
    transform: scale(1.25) translate(-20px, 15px);
    transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
.flip-in-hor-bottom {
  animation: flip-in-hor-bottom var(--animationDuration) cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes flip-in-hor-bottom {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd var(--animationDuration) cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */
.tracking-in-expand-fwd {
  animation: tracking-in-expand-fwd var(--animationDuration) cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
.slide-in-top {
  animation: slide-in-top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation delayed-fade-in
 * ----------------------------------------
 */
.delayed-fade-in {
  animation: fade-in 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.75s;
}

@keyframes delayed-fade-in {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
.slide-in-bottom {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
.puff-in-center {
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(2px);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
.scale-in-center {
  animation: scale-in-center var(--animationDuration) cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
.swing-in-top-fwd {
  animation: swing-in-top-fwd var(--animationDuration) cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
.bounce-in-top {
  animation: bounce-in-top 1.1s both;
}

@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }

  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }

  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }

  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
.scale-in-center {
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-165px);
    transform: translateY(-165px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-165px);
    transform: translateY(-165px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-165px);
    transform: translateY(-165px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-165px);
    transform: translateY(-165px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom-top
 * ----------------------------------------
 */
.slide-bottom-top {
  -webkit-animation: slide-bottom-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom-top {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-bottom-top {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-top-bottom 
 * ----------------------------------------
 */
.slide-top-bottom {
  -webkit-animation: slide-top-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}
@keyframes slide-top-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}
